import http from '~/utils/http'


export class TaskApi {
  // @POST('/jav/task/getRuleLoad')
  // Future<String> getRuleLoad();
  static getRuleLoad(opt?: HttpOptions) {
    return http.post<any>({ ...opt, url: '/jav/task/getRuleLoad' })
  }

  // @POST('/jav/task/queryDaySignTaskList') //查询每日签到任务列表(展示签到使用)
  // Future<List<QueryDaySignTask>> queryDaySignTaskList();  //@Body() DaySignTaskParam param
  static queryDaySignTaskList(params: DaySignTaskParam, opt?: HttpOptions) {
    return http.post<Array<QueryDaySignTask>>({ ...opt, url: '/jav/task/queryDaySignTaskList', body: params })
  }

  // @POST('/jav/task/userSign') //保存每日签到任务
  // Future<String> userSign(@Body() UserSignParam param);
  static userSign(params: UserSignParam, opt?: HttpOptions) {
    return http.post<string>({ ...opt, url: '/jav/task/userSign', body: params })
  }

  // @POST('/jav/task/dailTaskDraw') //每日任务-观看奖励(分)/(次)奖励领取
  // Future<String> dailTaskDraw(@Body() DailTaskDrawParam param);
  static dailTaskDraw(params: DailTaskDrawParam, opt?: HttpOptions) {
    return http.post<string>({ ...opt, url: '/jav/task/dailTaskDraw', body: params })
  }

  // @POST('/jav/task/queryTaskCenterList') //任务中心列表
  // Future<List<TaskCenterQuery>> queryTaskCenterList();
  static queryTaskCenterList(opt?: HttpOptions) {
    return http.post<Array<TaskCenterQuery>>({ ...opt, url: '/jav/task/queryTaskCenterList' })
  }

  // /jav/task/queryAdList
  static queryAdList(params: UserSignAdParam, opt?: HttpOptions) {
    return http.post<Array<any>>({ ...opt, url: '/jav/task/queryAdList', body: params })
  }
}
